ul {
  list-style-type: none;
  /* display: flex;
  justify-content: center; */
  /* width: 50%;
  min-width: 500px;
  padding: 0;
  margin: 0 auto; */
}

a {
  position: relative;
  display: inline-block;
  margin: 15px 25px;
  outline: none;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.35em;
  height: 2em;
  line-height: 2em;
}

a:hover,
a:focus {
  outline: none;
}

section.effect-9 li.center-border a:before {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.15s width ease-out, 0.15s height ease-out 0.2s,
    0.15s top ease-out 0.2s;
}

section.effect-9 a {
  position: relative;
  padding: 0 10px;
  z-index: 1;
}
section.effect-9 a:before {
  content: "";
  display: block;
  position: absolute;
  width: 0%;
  height: 3px;
  z-index: -1;
  background-color: #1d9a2af5;
  /* 31d842f5 */
}
section.effect-9 a:hover:before {
  width: calc(100%);
  height: calc(100%);
}
